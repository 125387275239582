import {createRouter, createWebHistory} from '@ionic/vue-router';

const routes = [
    {
        path: '/',
        name: 'News',
        component: () => import ('../views/news/NewsOverviewDefault.vue')
    },
    {
        path: '/news-detail',
        name: 'NewsDetail',
        component: () => import ('../views/news/NewsDetailDefault.vue')
    },
    {
        path: '/calendar',
        name: 'CalendarOverview',
        component: () => import ('../views/calendar/CalendarOverviewDefault.vue')
    },
    {
        path: '/company',
        name: 'CompanyOverview',
        component: () => import ('../views/company/CompanyOverviewDefault.vue')
    },
    {
        path: '/messages',
        name: 'MessagesOverview',
        component: () => import ('../views/messages/MessagesOverviewDefault.vue')
    },
    {
        path: '/documents',
        name: 'DocumentsOverview',
        component: () => import ('../views/documents/DocumentsOverview.vue')
    },
    {
        path: '/profile',
        name: 'ProfileOverview',
        component: () => import ('../views/profile/ProfileOverview.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
