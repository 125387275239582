<template>

    <ion-buttons slot="end">
        <ion-button @click="setOpen(true)">
            <ion-icon slot="icon-only" :icon="searchOutline"></ion-icon>
        </ion-button>
        <ion-button id="click-trigger">
            <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
        </ion-button>
        <ion-popover trigger="click-trigger" trigger-action="click">
            <ion-list>
                <ion-item button>
                    <ion-label>Bericht</ion-label>
                </ion-item>
                <ion-item button>
                    <ion-label>Kalender item</ion-label>
                </ion-item>
            </ion-list>
        </ion-popover>
    </ion-buttons>

    <!-- Modal search: Component van maken? -->
    <ion-modal :is-open="isOpen">
        <ion-header>
            <ion-toolbar>
                <ion-title>Zoeken</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="setOpen(false)">Sluiten</ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <ion-searchbar></ion-searchbar>
        </ion-content>
    </ion-modal>
</template>

<script>

import { defineComponent } from 'vue';

import {searchOutline, addCircleOutline } from 'ionicons/icons'

import {
    IonButtons,
    IonButton,
    IonPopover,
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
    IonToolbar,
    IonHeader,
    IonModal,
    IonTitle,
    IonContent,
    IonSearchbar


} from '@ionic/vue'

export default defineComponent({
    name: 'HeaderButtons',

    components: {
        IonButtons,
        IonButton,
        IonPopover,
        IonList,
        IonItem,
        IonLabel,
        IonIcon,
        IonToolbar,
        IonHeader,
        IonModal,
        IonTitle,
        IonContent,
        IonSearchbar,
    },

    data() {
        return {
            searchOutline,
            addCircleOutline,
            isOpen: false,
        }
    },

    methods: {
        setOpen(isOpen) {
            this.isOpen = isOpen;
        },
    },
});
</script>

<style lang="scss" scoped>
ion-title {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>