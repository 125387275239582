<template>
    <ion-page>

        <!-- Header -->
        <slot name="header"></slot>

        <!-- Page Content -->
        <ion-content :fullscreen="true">

           <slot />

        </ion-content>

        <!-- tabs mobile -->
        <TabsMenu />
    </ion-page>
</template>

<script>

import { defineComponent } from 'vue';
import { isPlatform } from "@ionic/vue";

import TabsMenu from "@/partials/TabsMenu.vue";

import {
    IonPage,
    IonContent
} from '@ionic/vue';

export default defineComponent({
    name: 'PageMobile',
    components: {
        TabsMenu,
        IonContent,
        IonPage
    },

    props: {
        detail: Boolean
    },

    setup() {
        return {
            isPlatform
        }
    },
});
</script>