<template>
    <ion-page>

        <!-- Header -->
        <HeaderDesktop />

        <!-- Page Content -->
        <ion-content :fullscreen="true">
            <div class="desktop__overview">
                <div class="container">

                    <div class="overview__grid">
                        <div class="overview__grid__left">
                            <sidebar-inner>
                                <slot name="left"></slot>
                            </sidebar-inner>
                        </div>

                        <div class="overview__grid__center">
                            <slot name="center"></slot>
                        </div>

                        <div class="overview__grid__right">
                            <sidebar-inner>
                                <slot name="right"></slot>
                            </sidebar-inner>
                        </div>
                    </div>

                </div>
            </div>
        </ion-content>

    </ion-page>
</template>

<script>

import { defineComponent } from 'vue';
import HeaderDesktop from "@/views/header/desktop/HeaderDesktop.vue";

import {
    IonPage,
    IonContent,
    isPlatform

} from '@ionic/vue'

export default defineComponent({
    name: 'PageDesktop',
    components: {
        IonPage,
        IonContent,
        HeaderDesktop
    },

    setup() {
        return {
            isPlatform
        }
    },
});
</script>

<style lang="scss" scoped>
.desktop__overview {
    padding: 30px 0;
}

.overview__grid {
    display: grid;
    gap: 30px;
    grid-template-columns: 22% 1fr 22%;
    align-items: start;
}

.overview__grid__left,
.overview__grid__right {
    position: sticky;
    top: 15px;
    align-self: start;
}
</style>