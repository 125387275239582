<template>
    <ion-item>
        <ion-avatar slot="start">
            <img alt="Tom Elbers" src="https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166__340.jpg">
        </ion-avatar>
        <ion-label>
            Tom Elbers
            <p>Webdesigner</p>
        </ion-label>
    </ion-item>
</template>

<script>

import { defineComponent } from 'vue';

import {
    IonItem,
    IonAvatar,
    IonLabel
} from '@ionic/vue'

export default defineComponent({
    name: 'PersonCard',
    components: {
        IonItem,
        IonAvatar,
        IonLabel
    },
});
</script>

<style lang="scss" scoped>
    .plt-desktop {
        .person__block__card {
            ion-item {
                --background: #fff;
                --border-color: #fff;
                --padding-top: 10px;
                --padding-bottom: 10px;
            }
        }

        ion-avatar {
            width: 50px;
            height: 50px;
        }

        ion-item {
            font-size: 16px;

            p {
                font-size: 13px;
                margin-top: 3px;
            }
        }
    }
</style>