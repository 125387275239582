<template>
    <div class="sidebar__block">
        <slot></slot>
    </div>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SidebarBlock',
});
</script>