<template>
    <div class="tabs">
        <ion-buttons slot="bottom">
            <ion-button router-link="/" :class="{ 'tab__active' : activeTab == 'news' }" color="medium">
                <ion-icon :icon="homeOutline" slot="icon-only" />
            </ion-button>
            <ion-button router-link="/calendar" :class="{ 'tab__active' : activeTab == 'calendar' }" color="medium">
                <ion-icon :icon="calendarOutline" slot="icon-only" />
            </ion-button>
            <ion-button router-link="/company" :class="{ 'tab__active' : activeTab == 'company' }" color="medium">
                <ion-icon :icon="peopleOutline" slot="icon-only" />
            </ion-button>
            <ion-button router-link="/messages" :class="{ 'tab__active' : activeTab == 'messages' }" color="medium">
                <ion-icon :icon="notificationsOutline" slot="icon-only" />
            </ion-button>
            <ion-button color="medium">
                <ion-menu-button></ion-menu-button>
            </ion-button>
        </ion-buttons>
    </div>
</template>

<script>

import { defineComponent } from 'vue';

import {
    IonButtons,
    IonButton,
    IonIcon,
    IonMenuButton
} from '@ionic/vue';

import {
    homeOutline,
    calendarOutline,
    peopleOutline,
    notificationsOutline
} from 'ionicons/icons'

export default defineComponent({
    name: 'TabsMenu',
    components: {
        IonButtons,
        IonButton,
        IonIcon,
        IonMenuButton
    },

    setup() {
        return {
            homeOutline,
            calendarOutline,
            peopleOutline,
            notificationsOutline,
        }
    },

    computed: {

        activeTab() {
            if(this.$route.name === 'News' || this.$route.name === 'NewsDetail') {
                return 'news'
            }

            if(this.$route.name === 'CalendarOverview') {
                return 'calendar'
            }

            if(this.$route.name === 'CompanyOverview') {
                return 'company'
            }

            if(this.$route.name === 'MessagesOverview') {
                return 'messages'
            }

            return null;
        }
    },
});
</script>

<style scoped lang="scss">
.tabs {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #eee;
    box-shadow: 0 0 4px rgb(0 0 0 / 7%);
    z-index: 999;

    ion-buttons {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    .tab__active {
        ion-icon {
            color: var(--ion-color-primary);
        }
    }

    ion-icon {
        color: #92949c;
    }

    ion-menu-button {
        font-size: 34px;
        color: #92949c;
    }

    ion-button {
        height: 59px !important;
        width: auto !important;
        --border-radius: 0 !important;

        &.active {
            color: var(--ion-color-primary);
        }
    }
}

.plt-desktop {
    .tabs {
        position: relative;
        max-width: 550px;
        box-shadow: none;
        border: 0;
    }

    ion-icon {
        font-size: 32px;
    }
}
</style>
