<template>

    <ion-header :translucent="false" class="desktop__header">

        <div class="container">

            <div class="header__inner">

                <HeaderLogo />

                <TabsMenu />

                <HeaderButtons />

            </div>

        </div>

    </ion-header>

</template>

<script>

import { defineComponent } from 'vue';

import HeaderLogo from "@/components/header/HeaderLogo.vue";
import HeaderButtons from "@/components/header/HeaderButtons.vue";
import TabsMenu from "@/partials/TabsMenu.vue";

import {
    IonHeader
} from '@ionic/vue'

import {searchOutline, addCircleOutline } from 'ionicons/icons'

export default defineComponent({
    name: 'HeaderDesktop',
    components: {
        IonHeader,
        HeaderLogo,
        HeaderButtons,
        TabsMenu
    },

    data() {
        return {
            searchOutline,
            addCircleOutline,
            isOpen: false,
        }
    },

});
</script>

<style lang="scss" scoped>
    .desktop__header {
        background-color: #fff;
        border-bottom: 1px solid var(--ion-border-color);
        box-shadow: 0px 1px 6px rgb(0 0 0 / 5%);
        display: flex;
        align-items: center;
        justify-content: center;

        > .container {
            position: relative;
            left: -7px;
        }
    }

    .header__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
    }

</style>