<template>
    <div class="sidebar__inner">
        <slot></slot>
    </div>
</template>
<script>

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SidebarInner',
});
</script>


<style lang="scss" scoped>
    .sidebar__inner {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
</style>