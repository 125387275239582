<template>

    <!-- SidebarPerson -->
    <div class="person__block">
        <div class="person__block__card">
            <PersonCard />
        </div>

        <slot></slot>

    </div>

</template>

<script>

import { defineComponent } from 'vue';

import PersonCard from "@/components/person/PersonCard.vue";

import {
} from '@ionic/vue'

export default defineComponent({
    name: 'SidebarPerson',
    components: {
        PersonCard,
    },

});
</script>

<style lang="scss" scoped>
.person__block {
    background-color: #fff;
    box-shadow: var(--ion-box-shadow);
    border-radius: var(--ion-border-radius);
    border: 1px solid var(--ion-border-color);
    overflow: hidden;
}

.person__block__card {
    border-bottom: 1px solid var(--ion-border-color);
}
</style>