<template>
    <ion-list lines="inset">

        <PersonCard />

        <ion-menu-toggle >
            <ion-item button router-link="/company" :class="{ 'tab__active' : activeTab == 'company' }">
                <ion-icon :icon="libraryOutline" slot="start" />
                <ion-label>Mijn afdeling</ion-label>
            </ion-item>
            <ion-item button router-link="/documents" :class="{ 'tab__active' : activeTab == 'dcuments' }">
                <ion-icon :icon="documentsOutline" slot="start" />
                <ion-label>Handige documenten</ion-label>
            </ion-item>
            <ion-item button router-link="/profile" :class="{ 'tab__active' : activeTab == 'profile' }">
                <ion-icon :icon="personOutline" slot="start" />
                <ion-label>Mijn profiel</ion-label>
            </ion-item>
            <ion-item button router-link="#">
                <ion-icon :icon="logOutOutline" slot="start" />
                <ion-label>uitloggen</ion-label>
            </ion-item>
        </ion-menu-toggle>

    </ion-list>
</template>

<script>

import { defineComponent } from 'vue';
import PersonCard from "@/components/person/PersonCard.vue";

import {
    archiveOutline,
    libraryOutline,
    documentsOutline,
    personOutline,
    logOutOutline
} from 'ionicons/icons';

import {
    IonList,
    IonMenuToggle,
    IonItem,
    IonIcon,
    IonLabel,

} from '@ionic/vue'

export default defineComponent({
    name: 'SidebarMenu',
    components: {
        IonList,
        IonMenuToggle,
        IonItem,
        IonIcon,
        IonLabel,
        PersonCard
    },

    setup() {
        return {
            archiveOutline,
            libraryOutline,
            documentsOutline,
            personOutline,
            logOutOutline
        }
    },


    computed: {

        activeTab() {
            if(this.$route.name === 'CompanyOverview') {
                return 'company'
            }

            if(this.$route.name === 'DocumentsOverview') {
                return 'dcuments'
            }

            if(this.$route.name === 'ProfileOverview') {
                return 'profile'
            }

            return null;
        }
    },
});
</script>

<style lang="scss" scoped>
ion-item {
    --background: #fff;
    font-size: 15px;

    &.tab__active {
        --color: var(--ion-color-primary)
    }
}

ion-menu {
    --background: #fff;
}
</style>