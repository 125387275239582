import {IonicVue, isPlatform} from '@ionic/vue';
import { CapacitorUpdater } from '@capgo/capacitor-updater'

if(isPlatform('android') || isPlatform('ios')) {
  CapacitorUpdater.notifyAppReady()
}

import { createApp } from 'vue'
import App from './App.vue'
import router from './router';


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Scss styling */
import './assets/scss/app.scss'

const app = createApp(App)
  .use(IonicVue)
  .use(router);

import PageOverviewMobile from "@/views/slots/PageMobile.vue";
import PageOverviewDesktop from "@/views/slots/PageDesktop.vue";
import SidebarInner from "@/views/slots/sidebar/SidebarInner.vue";
import SidebarBlock from "@/views/slots/sidebar/SidebarBlock.vue";
import SidebarPerson from "@/views/slots/sidebar/SidebarPerson.vue";

app.component('page-mobile', PageOverviewMobile)
app.component('page-desktop', PageOverviewDesktop)
app.component('sidebar-inner', SidebarInner)
app.component('sidebar-block', SidebarBlock)
app.component('sidebar-person', SidebarPerson)


router.isReady().then(() => {
  app.mount('#app');
});